:root{
    --joune:#FFBB00;
}
.home{
    display: flex;
    flex-direction: column;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%,
    rgba(255,255,255,0)200%),  url(../../Assest/pexels-binyamin-mellish-1396122.jpg);
    position: absolute;
    top: 0px;
    padding-top: 100px;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    gap: 20px;
    align-items: center;
    padding-bottom: 50px;
}
.home h1{
    font-size: 5rem;
    color: var(--joune);
    font-weight: 800;
    margin: 0;
    margin-top: 150px;
}
.home p{
    color: white;
    margin: 0;
    font-size: 2rem;
    font-weight: 600;
}
.search{
    display: flex;
    flex-direction: row;
    padding: 20px;
    background-color: rgb(128 128 128 / 80%);
    width: max-content;
    border-radius: 14px;
    align-items: flex-start;
    gap: 20px;
}
.btnSearhc{
    color: white;
    background-color: var(--joune);
    border: none;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    font-size: 1.2rem;
    display: block;
}

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500;600;700&display=swap');
.number{
    width: 100%;
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 740px;
    gap: 200px;
    background-color: #EEEEEE;
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: center;
}
.number div {
    display: flex;
    flex-direction: column;
}
.number div span:nth-child(1){
    color: #86DC20;
    font-size: 4rem;
    font-weight: 600;
    font-family: 'Dancing Script', cursive;
}
.number div span:nth-child(2){
    color: gray;
    font-size: 20px;
}