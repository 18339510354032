:root{
    --joune:rgb(255, 187, 0);
}
.header{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    z-index: 2;
}
.logo img {
    width: 60px;
    height: max-content;
    object-fit: cover;
}
.bar{
    display: flex;
    flex-direction: row;
}
.bar ul{
    display: flex;
    flex-direction: row;
    list-style: none;
    gap: 30px;
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
    color: white;
}
.bar ul li a{
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
    color: white;
    
}
.bar ul li a.active {
    background:
    linear-gradient(
      to right,
      rgba(255, 187, 0, 1),
      rgba(255, 187, 0, 1)
    );
  background-size: 100% 3px;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  }
.bar ul li a:hover  {
    background:
    linear-gradient(
      to right,
      rgba(255, 187, 0, 1),
      rgba(255, 187, 0, 1)
    );
  background-size: 100% 3px;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  }
.cnx{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.cnx img{
    width: 30px;
    height: 30px;
}
.Btn{
    background: transparent;
    padding: 7px 20px 7px 20px;
    border: 2px solid var(--joune);
    font-size: 20px;
    border-radius: 10px;
    font-weight: 600;
    color: white;
}