.offer{
    display: flex;
    flex-direction: column;
    position: relative;
    top: 950px;
    background: #EEEEEE;
    align-items: center;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 50px;
}
.offer h1{
    font-size: 24px;
    text-align: center;
    font-weight: 700;
}
.special{
    display: flex;
    flex-direction: row;
    gap: 65px;
}
.box{
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding-bottom: 20px;
}
.box h4{
    width: max-content;
    color: orange;
    text-align: center;
    margin-bottom: 4px;
}
.box img {
    width: 100%;
    height: 90%;
}
.box p {
    color: gray;
    margin: 15px;
}
.box h1{
    color: orange;
    /* padding: 5px; */
    margin-bottom: 4px;
}
.box label {
    font-size: 20px;
    font-weight: bold;
}
.box label span{
    font-size: 15px;
    text-decoration: line-through;
    font-weight: 500;
}
.offer button{
    color: white;
    background-color: orange;
    border: none;
    padding: 10px 20px 10px 20px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 8px;
}