:root{
    --joune:rgb(255, 187, 0);
}

.about{
    display: flex;
    flex-direction: column;
    position: relative;
    top: 900px;
    padding-bottom: 30px;
    
}
.fistline{
    display: flex;
    flex-direction: row;
}
.text{
    align-items: flex-start;
}
.text p {
    width: 200px;
}
.text button{
    background-color: var(--joune);
    color: white;
    border: none;
}
.about{
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.fistline{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 250px 0px 250px;
}
.txt{
    text-align: left;
}
.txt2{
    text-align: left;
}
.txt p{
    width: 400px;
}
.txt button{
    color: white;
    background-color: orange;
    border: none;
    padding: 10px 20px 10px 20px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 8px;
}
.fistline img {
    width: 65%;
}
.secondline img {
    width: 65%;
}
.secondline{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 250px 0px 250px;
}
.i1{
    display: flex;
    justify-content: flex-end;
}
.i2{
    display: flex;
    justify-content: flex-start;
}
.txt2 p{
    width: 400px;
}
.txt2 button{
    color: white;
    background-color: orange;
    border: none;
    padding: 10px 20px 10px 20px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 8px;
}